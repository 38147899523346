//import axios from "axios";

export default () => {
  /*axios.get('https://timeapi.io/api/Time/current/zone?timeZone=Europe/Vilnius')
  .then(response => {
    const data = response.data;
    console.log(data);
    
    console.log(data.date); 
    console.log(data.time);
  })
  .catch(error => {
    console.error('Error fetching date:', error);
  });*/
    return new Date().getTime() > new Date("2025-05-09 04:00:00.000Z").getTime()
  }
import React from "react";
import { Link } from "gatsby";
import Rope from "../../assets/svg/6-Ruoskis-Egzaminui-is-anksto.svg";
import { RaisedButton } from "../Buttons";
import "./Ruoskis.scss";

const Ruoskis = () => {

    return (
        <div id="target2">
            <div className="train">
                <div className="trainAmba">
                    <div className="trainTry">
                        <h2>RUOŠKIS EGZAMINUI IŠ ANKSTO!</h2>
                        <p id="body">
                        Europos egzaminui gali pasiruošti spręsdamas bandomąją egzamino treniruotę ir susipažindamas su šių metų egzamino temomis bei informacijos šaltiniais. <br /> <br/>
                            Šių metų Europos egzamino klausimai bus apie Europos Sąjungos veikimą, istoriją, jaunimo galimybes ES, dalyvavimą demokratiniame gyvenime. Taip pat bus klausimų apie aktualijas – Europos Sąjungos solidarumą su Ukraina, gynybos politiką, ekonomikos augimą.

                        </p>
                        <br />
                        <Link to="/euegzaminas/isbandyk">
                            <RaisedButton>Pradėti treniruotę</RaisedButton>
                        </Link>
                        <a href="https://lithuania.representation.ec.europa.eu/europos-egzaminas-2025_lt#pasiruo%C5%A1k-egzaminui-i%C5%A1-anksto" target="_blank" rel="noreferrer">
                            <RaisedButton variant="blueB">Susipažinti su informacijos šaltiniais</RaisedButton>
                        </a>
                    </div>
                    <div className="jumpingImg">
                        <img src={Rope} alt="Šokdynės iliustracija"/>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Ruoskis;
import React from "react";
import { Link } from "gatsby";
import Rope from "../../assets/svg/7-Tap-europos-egzamino-ambasadoriumi.svg";
import { RaisedButton } from "../Buttons";
import "./Organizacijoms.scss";

const Organizacijoms = () => {

    return (
        <div id="target4">
            <div className="train">
                <div className="trainAmba">



                    <div className="trainTry">
                        <h2>ORGANIZACIJŲ IŠŠŪKIS!</h2>
                        <p id="body">

                            Kviečiame organizacijų ir įmonių darbuotojus dalyvauti komandiniame iššūkyje! Gegužės 9 d. dalyvaukite Europos egzamine – taip prasmingai paminėsite Europos dieną, pagilinsite žinias apie Europos Sąjungą, ugdysite komandinę dvasią bei pilietiškumą ir, žinoma, turėsite galimybę laimėti puikių prizų!

                        </p>
                        <br />
                        <a href="https://ec.europa.eu/eusurvey/runner/Europos_egzaminas_2025" target="_blank" rel="noreferrer">
                            <RaisedButton variant="blueB">NORIU DALYVAUTI ORGANIZACIJŲ IŠŠŪKYJE</RaisedButton>
                        </a>
                    </div>
                    {/*<div className="jumpingImg">
                        <img src={Rope} alt="Šokdynės iliustracija"/>
                    </div>*/}
                </div>
            </div>
        </div>
    );
}
export default Organizacijoms;
import React from "react";
import BannerImage1 from "../../assets/svg/1-Home-page.svg";
import BannerImage2 from "../../assets/svg/key-visual.svg"
import "./Home.scss";

function Home() {
  return (
      <div className="home">
        <div className="headerContainer">
          <h1> EUROPOS EGZAMINAS</h1>
          <h3>2025 m. gegužės 9 dieną</h3>
        </div>
        <div className="banner">
          <img src={BannerImage2} id="BannerImage2"  alt="Vaikų iliustracija - išspręsk, o tada švęsk" />
          <img src={BannerImage1} id="BannerImage1" alt="Vaikų iliustracija"/>
        </div>
      </div>
  );
}

export default Home;

import React from "react";
import { Link } from "gatsby";
import PeaceEU from "../../assets/svg/7-Tap-europos-egzamino-ambasadoriumi.svg";
import { RaisedButton, ArrowToliau } from "../Buttons";
import "./Ruoskis.scss";

const Ambasadorius = () => {
    return (
        <div id="target3">
            <div className="ambasa" >
                <div className="trainAmba" id="ambasaCon">
                    <div className="peaceImg">
                        <img src={PeaceEU} alt="EU taikos iliustracija"/>
                    </div>
                    <div className="trainTry" id="ambasada">
                        <h2>TAPK „EUROPOS EGZAMINO“ AMBASADORIUMI!</h2>
                        <p id="body">
                        Kviečiame mokyklų mokytojus tapti Europos egzamino ambasadoriais, skatinant mokinius ruoštis ir dalyvauti Europos egzamine. Mokytojai ambasadoriai, padėdami moksleiviams ruoštis Europos egzaminui, ne tik ugdo Europos piliečius, tačiau tampa ir aktyvios savo kolegų – kitų mokytojų ambasadorių – bendruomenės dalimi. Kviečiame Jus prisijungti prie virtualios mokytojų ambasadorių grupės, kurioje dalinsimės naudinga ir įdomia informacija!
                        </p>
                        <a href="https://ec.europa.eu/eusurvey/runner/mokytojai_ambasadoriai_2025" target="_blank" rel="noreferrer">
                            <RaisedButton> Užsiregistruok ir tapk ambasadoriumi</RaisedButton>
                        </a>
                        <br />
                                                
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Ambasadorius;